import { createBrowserRouter } from 'react-router-dom';
import BaseLayout from './components/base-layout';
import SuspenseLoader from './components/loader/suspense-loader';
import { lazy, Suspense } from 'react';

const Loader = (Component: any) => (props: any) =>
(
    <Suspense fallback={<SuspenseLoader />}>
        <Component {...props} />
    </Suspense>
);

const PageNotFound = Loader(lazy(() => import("./components/page-not-found")));

const HomePage = Loader(lazy(() => import("./contents/homepage")));
const Topics = Loader(lazy(() => import("./contents/topics")));
const Quiz = Loader(lazy(() => import("./contents/quiz")));
const Result = Loader(lazy(() => import("./contents/result")));
const ThankYou = Loader(lazy(() => import("./contents/thank-you")));

export const router = createBrowserRouter([
    {
        path: '/',
        element: <BaseLayout />,
        errorElement: <PageNotFound />,
        children: [
            { index: true, element: <HomePage /> },
            { path: "/select-topic", element: <Topics /> },
            { path: "/quiz", element: <Quiz /> },
            { path: "/result", element: <Result /> },
            { path: "/thank-you", element: <ThankYou /> },
        ]
    },
    {
        path: "*",
        element: <PageNotFound />,
        errorElement: <PageNotFound />,
    },
]);
