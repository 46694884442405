import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// quiz questions
export interface IQuestion {
    id: number;
    question: string;
    optionA: string;
    optionB: string;
    optionC: string;
    optionD: string;
    correctAnswer: string;
    image: string;
}

export interface IAnswer {
    questionNo: number,
    isCorrect: boolean;
    timeTaken: number;
}

// random quiz 
export interface IRandomQuestions {
    id: number;
    question: string;
    optionA: string;
    optionB: string;
    optionC: string;
    optionD: string;
    correctAnswer: string;
    questionNo: number;
    image: string;
    isCorrect: boolean;
    timeTaken: number;
}

interface IUSER {
    name: string;
    email: string;
    phoneNumber: string;
}

interface IRoot {
    user?: IUSER,
    totalQuestions: number,
    quizTimer: number,
    selectedTopics: number,
    selectedTopicQuizData: IQuestion[],
    randomQuestions: IRandomQuestions[];
    currentQuestion: IRandomQuestions | undefined;
    selectedOption: string | undefined;
}

const initialState: IRoot = {
    user: {
        name: "",
        email: "",
        phoneNumber: "",
    },
    totalQuestions: 5,
    quizTimer: 15,
    selectedTopics: 0,
    selectedTopicQuizData: [],
    randomQuestions: [],
    currentQuestion: undefined,
    selectedOption: undefined,
};

const slice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUser(state, action) {
            state.user = action.payload;
        },
        selectedTopics(state, action: PayloadAction<number>) {
            state.selectedTopics = action.payload;
        },
        selectedTopicQuizData(state, action: PayloadAction<IQuestion[]>) {
            state.selectedTopicQuizData = action.payload;
        },
        resetAll(state) {
            state.selectedTopics = 0;
            state.selectedTopicQuizData = [];
            state.randomQuestions = [];
            state.currentQuestion = undefined;
            state.selectedOption = undefined;
        },
        randomQuestions(state, action: PayloadAction<IRandomQuestions[]>) {
            state.randomQuestions = action.payload;
        },
        updateIsCorrect(state, action: PayloadAction<IAnswer>) {
            const { questionNo, isCorrect, timeTaken } = action.payload;

            // get the index in randomQuestions based on questionNo
            const index = state.randomQuestions.findIndex((question: IRandomQuestions) => question.questionNo === questionNo);

            if (index !== -1) {
                state.randomQuestions[index].isCorrect = isCorrect;
                state.randomQuestions[index].timeTaken = timeTaken;
            }
        },
        currentQuestion(state, action: PayloadAction<IRandomQuestions>) {
            state.currentQuestion = action.payload;
        },
        selectedOption(state, action: PayloadAction<string | undefined>) {
            state.selectedOption = action.payload;
        },
    },
});

export const UserActions = slice.actions;

export const reducer = slice.reducer;

export default slice;
