import { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import { Outlet } from "react-router-dom";
import { Box, styled } from "@mui/system";

const backgroundCOver = process.env.PUBLIC_URL + "/assets/images/backgroundCover.png";
const logo = process.env.PUBLIC_URL + "/assets/images/logo.svg";

const Wrapper = styled(Box)(() => ({
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    backgroundImage: `url(${backgroundCOver})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "100% 100%",
}));

const LogoWrapper = styled(Box)(() => ({
    width: "auto",
    height: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    bottom: "2rem",
    left: "2rem",
    zIndex: 1,
}));

interface IBaseLayoutProps {
    children?: ReactNode;
}

const BaseLayout: FC<IBaseLayoutProps> = () => {
    const handleFullScreen = () => {
        if (document.fullscreenElement) {
            document.exitFullscreen();
        } else {
            document.documentElement.requestFullscreen();
        }
    };
    return (
        <Wrapper>
            <LogoWrapper>
                <img src={logo} alt="logo" width={"150px"} onClick={handleFullScreen} />
            </LogoWrapper>
            <Outlet />
        </Wrapper>
    );
};

BaseLayout.propTypes = {
    children: PropTypes.node,
};

export default BaseLayout;
