import { combineReducers } from '@reduxjs/toolkit';
import { reducer as user } from '../slices/user';
import { reducer as persistStore } from '../slices/persistStore';

const rootReducer = combineReducers({
  user,
  persistStore
});

export default rootReducer;
